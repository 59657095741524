var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-algorithm"},[_c('div',{staticClass:"page-title-row"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.management'))+" ")]),_c('div',{staticClass:"bread-crumb"},[_c('router-link',{staticClass:"title-link",attrs:{"to":{ name: 'PageAlgorithmList' }}},[_vm._v(" "+_vm._s(_vm.$t('algorithm.name'))+" ")]),_c('span',{staticClass:"divider"},[_vm._v("/")]),_vm._v(_vm._s(_vm.$t('algorithm.predictiveMaintenance'))+" ")],1)]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.trainDataSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataSource'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              {value: 1, name: _vm.$t('algorithm.foxconnDemo')} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataFrame'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: 'TemperatureData' },
              { value: 2, name: 'molding' } ]}})],1)]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetParamColumn'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.temperature')} ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.timeColumn'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.startTime') } ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title must"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.statusColumn'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.status') } ]}})],1)])])]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.modelParamSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.modelType'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
            { value: 1, name: _vm.$t('algorithm.unsupervised') },
            { value: 2, name: _vm.$t('algorithm.supervised') } ]}})],1),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.specificationUpperLimit'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":""}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.specificationLowerLimit'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":""}})])]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.regulatoryCapRatio'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"0.5~1"},domProps:{"value":0.95}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.lowerControlRatio'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"0~0.5"},domProps:{"value":0.05}})])]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.timeUnit'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":1,"option-list":[
              { value: 1, name: _vm.$t('algorithm.second') },
              { value: 2, name: _vm.$t('algorithm.minute') },
              { value: 3, name: _vm.$t('algorithm.hour') } ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.earlyTime'))+": ")]),_c('input',{staticClass:"input",attrs:{"placeholder":_vm.$t('algorithm.timeUnitSeeAbove'),"type":"text"},domProps:{"value":60}})])]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.windowSize'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"},domProps:{"value":60}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.proportionOfTrainningSet'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"0~1"},domProps:{"value":0.8}})]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.warningThreshold'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"}})]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.abnormalThreshold'))+": ")]),_c('input',{staticClass:"input",attrs:{"type":"text"}})])])])]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"board-title-row"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.realTimeDataSetting'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataSource'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"option-list":[
              { value: 1, name: _vm.$t('algorithm.foxconnDemo') } ]}})],1),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.targetDataFrame'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"option-list":[
              { value: 1, name: 'TemperatureData' },
              { value: 2, name: 'molding' } ]}})],1)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"item-title"},[_vm._v(" "+_vm._s(_vm.$t('algorithm.executionCycle'))+": ")]),_c('default-select',{staticClass:"tag-select input",attrs:{"value":5,"option-list":[
            { value: 1, name: _vm.$tc('algorithm.second', 1) },
            { value: 2, name: _vm.$tc('algorithm.second', 15 ) },
            { value: 3, name: _vm.$tc('algorithm.second', 30) },
            { value: 4, name: _vm.$tc('algorithm.minuteLong', 1) },
            { value: 5, name: _vm.$tc('algorithm.minuteLong', 10) },
            { value: 6, name: _vm.$tc('algorithm.minuteLong', 15) },
            { value: 7, name: _vm.$tc('algorithm.minuteLong', 30) },
            { value: 8, name: _vm.$tc('algorithm.minuteLong', 40) },
            { value: 9, name: _vm.$tc('algorithm.minuteLong', 45) },
            { value: 10, name: _vm.$tc('algorithm.hourLong', 1) } ]}})],1)])]),_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn btn-outline",attrs:{"type":"button"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":_vm.back}},[_c('span',[_vm._v(_vm._s(_vm.$t('button.confirm')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }